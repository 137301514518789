import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "400",
    fontSize: "2.5em",
    paddingTop: "8px",
    paddingBottom: "12px",
    color: theme.palette.secondary.main,
  },
}))

const PageHeader = props => {
  const classes = useStyles(props)
  return (
    <Typography variant="button" component="h2" className={classes.title}>
      {props.title}
    </Typography>
  )
}

export default PageHeader
